<template>
  <main class="support-page container">
    <section class="support-page__banner">
      <img
        src="	https://static-goengines.gocase.com.br/qr-img/92006774312537761658846773847.jpeg"
        alt="Suporte Gocase"
        loading="lazy"
      />
      <h1>Central de Ajuda</h1>
    </section>
    <section class="support-page__faq"></section>
  </main>
</template>

<script>
import loadScript from '~/utils/loadScript'

export default {
  middleware({ $isGlobalStore, error }) {
    if ($isGlobalStore) {
      return error({
        statusCode: 404,
        message: 'Page not found',
      })
    }
  },
  mounted() {
    this.loadFaqScript() && this.loadWppScript()
  },
  methods: {
    loadFaqScript() {
      loadScript(
        'https://faq.directtalk.com.br/1.0/static/dist/dt-faq.js?token=a33a5ec2-95a0-43c7-b5a2-51fdb9ebd26d&appearance=aadf74ef-f869-4fec-ab21-e4d964ece18a',
        {
          attrs: {
            id: 'dt-faq-script',
          },
          parent: document.querySelector('.support-page__faq'),
          once: false,
        }
      )

      return true
    },
    loadWppScript() {
      const url =
        'https://wati-integration-service.clare.ai/ShopifyWidget/shopifyWidget.js?13956'
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.id = 'wati-integration'
      s.src = url
      const options = {
        enabled: true,
        chatButtonSetting: {
          backgroundColor: '#4dc247',
          ctaText: 'Fale Conosco',
          borderRadius: '25',
          marginLeft: '0',
          marginBottom: '50',
          marginRight: '50',
          position: 'right',
        },
        brandSetting: {
          brandName: 'Gocase',
          brandSubTitle: 'Chat ativo de segunda à sexta das 08h às 18:00h.',
          brandImg:
            'https://theme.zdassets.com/theme_assets/839790/22fd3bbb71bc0589bc7e6156c87a849739cdf25f.jpg',
          welcomeText: 'Olá tudo bem? 😊\nComo podemos te ajudar?',
          messageText: 'Olá, sou cliente da Gocase e preciso de uma ajuda.',
          backgroundColor: '#0a5f54',
          ctaText: 'Iniciar conversa',
          borderRadius: '25',
          autoShow: false,
          phoneNumber: '558584398586',
        },
      }
      s.onload = function () {
        window.CreateWhatsappChatWidget(options)
      }
      const x = document.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    },
  },
  head() {
    return {
      title: 'Central de Ajuda',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Página de Suporte - Central de Ajuda - Gocase',
        },
      ],
    }
  },
}
</script>

<style>
.support-page__banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.support-page__banner img {
  width: 100%;
  object-fit: contain;
}

.support-page__banner h1 {
  font-size: 25px;
  position: absolute;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  top: calc(50% - 25px);
  background: white;
}
</style>
